<template>
    <div>
        <NavBar
            style="max-height: 50px; margin-bottom: 10px"
            :username="currentUserName"
            :pageName="pageName"
        ></NavBar>
        <v-app>
            <div>
                <v-row justify="center">
                  <v-dialog v-model="editCorporatePackageModel" persistent max-width="700">
                    <v-card>
                      <v-card-title class="createCorporateDilogTitle">
                        <div>
                          <p style="font-weight:bold;margin-top:10px" >
                            Edit Package
                            </p>
                        </div>
                        <div>
                          <v-btn  icon @click="editCorporatePackageModel=false">
                          <img src="@/assets/popupClose.svg" >
                          </v-btn>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Package Name <span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="newPackageName"
                                        :placeholder="packageName"
                                        solo
                                        :rules="[v=> !!v || 'Required']"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Description <span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="description"
                                        placeholder="Enter Description Here"
                                        solo
                                        :rules="[v=> !!v || 'Required']"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Description (Burmese) <span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="descriptionMM"
                                        placeholder="Enter Description Here"
                                        solo
                                        :rules="[v=> !!v || 'Required']"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>

                            <div class="addSingleMemberMainClass">
                                <div style="width: 50%;">
                                    <div style="text-align:left">
                                        <p >Banner</p>
                                        <p >Recommended size: <b>1029 x 335</b></p>
                                    </div>
                                    <br>
                                </div>
                                <div style="width: 50%;">
                                    <div>
                                    <v-file-input @change="getPics" v-model="uploadedBanner" accept="image/jpeg,image/png" />
                                    </div>
                                    <div v-if="imageURL">
                                    <img height="100px" width="200px" :src = "imageURL" />
                                    </div>
                                </div>
                            </div>

                        </div>
                      </v-card-text>
                      <v-card-actions style="display:flex; justify-content:flex-end">
                        <div >
                          <v-btn
                          color="primary"
                          width="100px"
                          @click="editCorporatePackage"
                          :disabled = "checkDisabled()"
                          :loading = "isSaveClicked"
                          >
                          Save
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
            </div>

            <div>
                <v-row justify="center">
                  <v-dialog v-model="viewClaimedAppointmentModel" persistent max-width="700">
                    <v-card>
                      <v-card-title class="createCorporateDilogTitle">
                        <div>
                          <p style="font-weight:bold;margin-top:10px" >
                            View Appointments
                            </p>
                        </div>
                        <div>
                          <v-btn  icon @click="viewClaimedAppointmentModel=false">
                          <img src="@/assets/popupClose.svg" >
                          </v-btn>
                        </div>
                      </v-card-title>
                      <v-card-text align="left">
                        <p style="font-weight: 600">Employee: {{current_display_user}}</p>
                      </v-card-text>
                      <v-card-text>
                        <div v-if="claimed_appointments.length > 0">
                            <table class="tableClass">
                                <tr>
                                    <th>App. No.</th>
                                    <th>Patient Name</th>
                                    <th>Appointment Date</th>
                                    <th>Doctor Name</th>
                                    <th>Specialization Name</th>
                                    <th>Actions</th>
                                </tr>
                                <tr v-for="claimedRecord in claimed_appointments" :key="claimedRecord._id">
                                    <td>{{claimedRecord.appointment_data[0].appointment_reference_number}}</td>
                                    <td>{{claimedRecord.appointment_data[0].booked_for_name}}</td>
                                    <td>{{claimedRecord.appointment_data[0].date_of_appointment}}</td>
                                    <td>{{claimedRecord.appointment_data[0].doctor_name}}</td>
                                    <td>{{claimedRecord.appointment_data[0].specialization_name}}</td>
                                    <td>
                                        <v-tooltip bottom>
                                           <template v-slot:activator="{ on }">
                                             <a style="width: 100%" icon @click="viewParticularAppointment(claimedRecord.appointment_data[0]._id)" depressed v-on="on" class="editAppointment" >
                                               <img src="https://d3vsnl8idgwrlw.cloudfront.net/show_pwd.svg" style="height: 24px; width: 24px;"/>
                                             </a>
                                           </template>
                                           <span>View</span>
                                         </v-tooltip>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else>
                            <p style="font-weight: 300;color: red;">No claimed appointments</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-row>
            </div>
            <div>
                <v-row justify="center">
                  <v-dialog v-model="addSingleMembersModal" persistent max-width="700">
                    <v-card>
                      <v-card-title class="createCorporateDilogTitle">
                        <div>
                          <p style="font-weight:bold;margin-top:10px" >
                            Add Members
                            </p>
                        </div>
                        <div>
                          <v-btn  icon @click="addSingleMemberModalClose">
                          <img src="@/assets/popupClose.svg" >
                          </v-btn>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Name<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="nameOfMember"
                                        placeholder="Enter Name Here"
                                        solo
                                        :rules="[v=>!!v || 'Required']"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Phone Number<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="phone_number"
                                        placeholder="Phone Number"
                                        :rules="[v=>!!v || 'Required', v=> !(isNaN(v)) || 'Must be a number', v => !(v.length < 7 || v.length > 11) || '7-11 digits only']"
                                        solo
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Employee ID</p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="employeeIdOfMember"
                                        placeholder="Enter Employee ID"
                                        solo
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                                <!-- <div class="titleAndTextFiledClass" style="margin-right:115px">
                                    <div>
                                        <p style="text-align:left">Date Of Birth</p>
                                    </div>
                                    <div>
                                        <input type="date" v-model="dobOfMember" background-color="#F1F6FF"/>
                                    </div>
                                </div> -->
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Start Date<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <input type="date" v-model="startDateForMember" background-color="#F1F6FF" />
                                    </div>
                                </div>
                                <div class="titleAndTextFiledClass" style="margin-right:115px">
                                    <div>
                                        <p style="text-align:left">End Date<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <input type="date" v-model="endDateForMember" background-color="#F1F6FF"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </v-card-text>
                      <v-card-actions style="display:flex; justify-content:flex-end">
                        <div >
                          <v-btn
                          color="primary"
                          width="100px"
                          @click="createMember"
                          :disabled = "isSaveClicked || (phone_number == '' || nameOfMember == '' || startDateForMember == '' || endDateForMember == '')"
                          >
                          Save
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
            </div>
            <div>
                <v-row justify="center">
                  <v-dialog v-model="editSingleMemberModal" persistent max-width="700">
                    <v-card>
                      <v-card-title class="createCorporateDilogTitle">
                        <div>
                          <p style="font-weight:bold;margin-top:10px" >
                            Edit Member
                            </p>
                        </div>
                        <div>
                          <v-btn  icon @click="closeEditEmployee">
                          <img src="@/assets/popupClose.svg" >
                          </v-btn>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Name: </p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="nameOfMember"
                                        placeholder="Enter Name Here"
                                        solo
                                        :rules="[v=>!!v || 'Required']"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <span style="text-align:left">Phone Number: </span>
                                    </div>
                                    <div>
                                        <strong style="padding-top: 5px">{{ phone_number }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Employee ID: </p>
                                    </div>
                                    <div>
                                        <v-text-field
                                        v-model="employeeIdOfMember"
                                        placeholder="Enter Employee ID"
                                        solo
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                                <!-- <div class="titleAndTextFiledClass">
                                    <div>
                                        <span style="text-align:left">Date Of Birth: </span> {{ dobOfMember }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="addSingleMemberMainClass">
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">Start Date<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <input type="date" v-model="startDateForMember" background-color="#F1F6FF"/>
                                    </div>
                                </div>
                                <div class="titleAndTextFiledClass">
                                    <div>
                                        <p style="text-align:left">End Date<span style="color:red">*</span></p>
                                    </div>
                                    <div>
                                        <input type="date" v-model="endDateForMember" background-color="#F1F6FF"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </v-card-text>
                      <v-card-actions style="display:flex; justify-content:flex-end">
                        <div >
                          <v-btn
                          color="primary"
                          width="100px"
                          @click="editMember"
                          :disabled = "isSaveClicked || (phone_number == '' || nameOfMember == '' || startDateForMember == '' || endDateForMember == '')"
                          >
                          Update
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
            </div>
            <div style="background-color: #EFF4FF; height: 94.5vh !important">
                <v-row cols="12">
                    <v-col cols="2">
                        <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
                    </v-col>
                    <v-col cols="10" class="scrollFix">
                        <div style="margin:40px">
                            <v-row style="margin-left: 5px" >
                                <div>
                                    <span
                                    style="font-weight: 800;
                                    font-size: 12pt;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;">
                                    <h3>View Package</h3>
                                    </span>
                                </div>

                            </v-row>
                            <div v-if="!isLoading">
                                <v-card class="corporateNameCard" elevation = 0>
                                    <div class="textAndButtonClass">
                                        <div class="corporateContentClass">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important">Corporate Program Name: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                            <p style="margin-bottom:0px !important"> &nbsp; {{packageName}} <span style="margin:8px; color: #828282;">|</span></p>
                                            </div>
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important">Company Name: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                            <p style="margin-bottom:0px !important"> &nbsp; {{companyName}} </p>
                                            </div>
                                        </div>

                                        <div>
                                            <v-btn class="warning" style="height: 24px;" @click="openEditPackagePopup">
                                                EDIT
                                            </v-btn>
                                        </div>
                                    </div>

                                    <div class="descriptionRow">
                                        <div class="viewCorporatePackageTitle">
                                            <p style="margin-bottom:0px !important">Description: </p>
                                        </div>

                                        <div class="viewCorporatePackageValue">
                                            <p style="margin-bottom:0px !important"> &nbsp; {{packageDescription}} <span style="margin:8px; color: #828282;"></span></p>
                                        </div>
                                    </div>

                                    <div class="descriptionRow">
                                        <div class="viewCorporatePackageTitle">
                                            <p style="margin-bottom:0px !important">Description (Burmese): </p>
                                        </div>

                                        <div class="viewCorporatePackageValue">
                                            <p style="margin-bottom:0px !important"> &nbsp; {{packageDescriptionMM}} <span style="margin:8px; color: #828282;"></span></p>
                                        </div>
                                    </div>
                                </v-card>
                                <v-card class="viewCorporateBenefitsCard" elevation = 0 v-if="fupBenefit && JSON.stringify(fupBenefit) !== '{}'">
                                    <div class="programBenefitsClass">
                                        <div>
                                            <p class="viewBenefitsCardTitle">FUP Benefits</p>
                                        </div>
                                    </div>
                                    <div class="viewCorporateBenefitsDetails" v-if="JSON.stringify(fupBenefit) !== '{}'">
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Type: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;" >&nbsp;{{fupBenefit.package_type}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Discount Type: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{fupBenefit.promotion_type}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Discount Amount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{fupBenefit.discount}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Min Spent Amount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{fupBenefit.minimum_spend ? fupBenefit.minimum_spend : "-" }} </p>
                                            </div>
                                        </div>
                                        <div v-if="benefitFirstRow && benefitFirstRow.promotion_type=='PERCENTAGE_OFF'" style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Max Discount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{fupBenefit.max_discount ? fupBenefit.max_discount : "-" }} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="JSON.stringify(fupBenefit) !== '{}'" align="left" class="viewCorporateBenefitsDetails" style="margin-top: 0px; justify-content: flex-start !important;">
                                        <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Frequency-Days: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{fupBenefit.frequency ? fupBenefit.frequency.number_of_days : "-"}} </p>
                                            </div>
                                        </div>
                                        <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Frequency-Count: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{fupBenefit.frequency ? fupBenefit.frequency.booking_count : "-"}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="JSON.stringify(fupBenefit) !== '{}'" align="left" class="viewCorporateBenefitsDetails" style="margin-top: 8px;">
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">List: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p class="wordBreakClass"
                                                style="margin-bottom:0px !important;">  &nbsp;{{fupBenefit.applicable_list_str.join(", ")}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div align="center" v-else>
                                        <div>
                                            No Benefits
                                        </div>
                                    </div>
                                </v-card>
                                <v-card class="viewCorporateBenefitsCard" elevation = 0>
                                    <div class="programBenefitsClass">
                                        <div>
                                            <p class="viewBenefitsCardTitle">Program Benefits</p>
                                        </div>
                                        <div>
                                            <v-btn class="warning" style="height: 24px;" @click="editPackage"><span v-if="benefitFirstRow">Edit</span><span v-else>Add</span></v-btn>
                                        </div>
                                    </div>
                                    <div class="viewCorporateBenefitsDetails" v-if="benefitFirstRow">
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Type: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;" >&nbsp;{{benefitFirstRow.package_type}} </p>
                                                <!-- <p style="margin-bottom:0px !important;" v-else> &nbsp;Specialiazation </p> -->
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Discount Type: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{benefitFirstRow.promotion_type}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Discount Amount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{benefitFirstRow.discount}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Count: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{benefitFirstRow.promotion_count}} </p>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Min Spent Amount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{benefitFirstRow.minimum_spend ? benefitFirstRow.minimum_spend : "-" }} </p>
                                            </div>
                                        </div>
                                        <div v-if="benefitFirstRow && benefitFirstRow.promotion_type=='PERCENTAGE_OFF'" style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Max Discount: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp;{{benefitFirstRow.max_discount ? benefitFirstRow.max_discount : "-" }} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="benefitFirstRow" align="left" class="viewCorporateBenefitsDetails" style="margin-top: 0px; justify-content: flex-start !important;">
                                        <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Frequency-Days: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{benefitFirstRow.frequency ? benefitFirstRow.frequency.number_of_days : "-"}} </p>
                                            </div>
                                        </div>
                                        <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">Frequency-Count: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p style="margin-bottom:0px !important;"> &nbsp; {{benefitFirstRow.frequency ? benefitFirstRow.frequency.booking_count : "-"}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="benefitFirstRow" align="left" class="viewCorporateBenefitsDetails" style="margin-top: 8px;">
                                        <div style="display: flex; justify-content: space-evenly;">
                                            <div class="viewCorporatePackageTitle">
                                                <p style="margin-bottom:0px !important;">List: </p>
                                            </div>
                                            <div class="viewCorporatePackageValue">
                                                <p class="wordBreakClass"
                                                style="margin-bottom:0px !important;">  &nbsp;{{benefitFirstRow.applicable_list_str.join(", ")}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div align="center" v-else>
                                        <div>
                                            No Benefits
                                        </div>
                                    </div>

                                    <v-card-actions v-if="remaingBenefitsRow.length != 0">
                                        <div style="display:flex; margin-left:43% !important">
                                            <v-btn
                                             @click="show = !show"
                                             class="showMoreBtn"
                                             outlined
                                             v-if="!show"
                                            >
                                                Show More<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                            <v-btn
                                             @click="show = !show"
                                             class="showMoreBtn"
                                             outlined
                                             v-else
                                            >
                                                Show Less<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                    <transition-group v-show="show" v-if="remaingBenefitsRow.length > 0">
                                    <div  style="padding-bottom: 30px;" v-for="item in remaingBenefitsRow" :key="item.id">
                                        <div class="viewCorporateBenefitsDetails">
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Type: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.package_type}} </p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Discount Type: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.promotion_type}} </p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Discount Amount: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.discount}} </p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Count: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.promotion_count}} </p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Min Spent Amount: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.minimum_spend}} </p>
                                                </div>
                                            </div>
                                            <div v-if="item.promotion_type =='PERCENTAGE_OFF'" style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Max Discount: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.max_discount}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div align="left" class='viewCorporateBenefitsDetails' style="margin-top: 0px; justify-content: flex-start !important;">
                                            <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Frequency-Days: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.frequency ? item.frequency.number_of_days : "-"}} </p>
                                                </div>
                                            </div>
                                            <div style="margin: 0px 10px 0px 0px; display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">Frequency-Count: </p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p style="margin-bottom:0px !important;"> &nbsp; {{item.frequency ? item.frequency.booking_count : "-"}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div align="left" class='viewCorporateBenefitsDetails' style="margin-top: 8px">
                                            <div style="display: flex; justify-content: space-evenly;">
                                                <div class="viewCorporatePackageTitle">
                                                    <p style="margin-bottom:0px !important;">List: &nbsp</p>
                                                </div>
                                                <div class="viewCorporatePackageValue">
                                                    <p class="wordBreakClass" style="margin-bottom:0px !important;">  {{item.applicable_list_str.join(", ")}} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </transition-group>
                                </v-card>
                                <div>
                                    <v-row style="margin-left: 5px; margin-top: 48px; margin-bottom: 15px;" class="pageHeaderClass">
                                        <div style="margin-top:24px">
                                            <span
                                            style="font-weight: 800;
                                            font-size: 12pt;
                                            text-transform: uppercase;
                                            letter-spacing: 1px;
                                            ">
                                            <h3>Members</h3>
                                            </span>
                                        </div>
                                        <div class="memberlistPageAction">
                                            <div>
                                                <v-text-field
                                                    v-model="memberFilter"
                                                    label="Search"
                                                    dense
                                                    style="margin-right:8px"
                                                    solo
                                                >
                                                    <template v-slot:append>
                                                    <v-btn icon>
                                                        <img
                                                        style="width: 25px; height: 25px"
                                                        src="https://d3vsnl8idgwrlw.cloudfront.net/search.svg"
                                                        />
                                                    </v-btn>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                            <div style="margin-right:8px">
                                                <v-btn color="primary" disabled>
                                                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/whiteUploadIcon.svg" > Upload
                                                </v-btn>
                                            </div>
                                            <div style="margin-right:8px" >
                                                <v-btn color="primary" disabled>
                                                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/whiteUploadIcon.svg" > Download
                                                </v-btn>
                                            </div>
                                            <div style="margin-right:8px">
                                                <v-btn color="primary" @click="openAddPopup" >
                                                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/whiteUploadIcon.svg" > Add Members
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-row>
                                </div>
                                <v-data-table
                                    :headers="headers"
                                    :items="memberListOfPackage"
                                    class="corporatePackageTable"
                                    :search="memberFilter"
                                    >
                                    <template v-slot:item.action="{item}" >
                                        <v-btn class="mx-1" icon :disabled="isViewClicked" @click="viewEmployeeDets(item)">
                                            <img src="https://d3vsnl8idgwrlw.cloudfront.net/show_pwd.svg" style="height: 24px; width: 24px;"/>
                                        </v-btn>
                                        <v-btn class="mx-1" icon @click="editEmployee(item)">
                                            <img src="https://d3vsnl8idgwrlw.cloudfront.net/edit.svg" />
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                            <div v-else>
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';

export default{
    name:"ViewCorporate",
    data(){
        return{
            pageName: "ViewCorporatePackage",
            currentUser: "",
            currentUserName: "",
            show:false,
            memberFilter:"",
                  headers: [
          {
            text: 'Employee ID',
            align: 'start',
            sortable: false,
            value: 'employee_id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Phone Number', value: 'phone_number' },
          { text: 'Start Date', value: 'start_date' },
          { text: 'End Date', value: 'end_date' },
          { text: 'Action', value: 'action' },
        ],
        packageID:"",
        packageDescription : "",
        packageDescriptionMM: "",
        memberListOfPackage:[],
        packageName:"",
        companyName:"",
        packageBenefits:[],
        benefitFirstRow:"",
        remaingBenefitsRow:[],
        addSingleMembersModal: false,
        employeeIdOfMember: "",
        nameOfMember: "",
        phone_number: "",
        startDateForMember: "",
        endDateForMember: "",
        employeesList : [],
        editSingleMemberModal: false,
        isLoading: true,
        isSaveClicked: false,
        employee_id: "",
        editCorporatePackageModel: false,
        newPackageName : '',
        description : '',
        descriptionMM : '',
        claimed_appointments: [],
        viewClaimedAppointmentModel: false,
        current_display_user: "",
        isViewClicked: false,
        fupBenefit: {},
        imageURL: "",
        uploadedBanner: null,
        attachmentFiles: null,
        fileTypes: ["image/jpeg", "image/png"],
        sampleFileLists:[],
        filesArray:[],
        bannerName: "",
        }
    },
    components: {
      NavBar,
      SideBar,
    },
    mounted(){
        this.pageName = "ViewCorparatePackage";
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
        this.$router.push({
            name: "AdminLogin",
        });
        } else {
            var params = this.$route.params.package_id;
            this.packageID = params
            var getAdminBody ={
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType: 'ADMIN'
            }
            axios_auth_instance_admin.post("/getAdmin", getAdminBody)
            .then((getAdminResponse)=>{
                this.createdBy = getAdminResponse.data.data._id
                var readCorporatePackageByIdWithMemberBody ={
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                    corporatePackageId: this.packageID
                }
                const config = {
                    method: 'post',
                    url: '/readCorporatePackageByIdWithMembers',
                    data: readCorporatePackageByIdWithMemberBody
                }
                axios_auth_instance_admin(config)
                .then((readCorporatePackageByIdWithMembersResponse)=>{

                    let responseData = readCorporatePackageByIdWithMembersResponse.data.corporate_package_data
                    this.packageBenefits = responseData.benefits
                    this.packageName = responseData.corporate_program_name
                    this.packageDescription = responseData.description ? responseData.description : ''; 
                    this.packageDescriptionMM = responseData.descriptionMM ? responseData.descriptionMM : ''; 
                    this.bannerName = responseData.fileName
                    this.companyName = readCorporatePackageByIdWithMembersResponse.data.corporate_master_data.company_name
                    this.memberListOfPackage = readCorporatePackageByIdWithMembersResponse.data.corporate_members
                    this.benefitFirstRow = this.packageBenefits[0]
                    this.remaingBenefitsRow = this.packageBenefits.slice(1)
                    this.fupBenefit = responseData.fup
                    this.isLoading = false;
                })
                .catch((readCorporatePackageByIdWithMembersError)=>{
                    this.isLoading = false;
                    handleError(readCorporatePackageByIdWithMembersError, this.$router, 'readCorporatePackageByIdWithMembersError', 'AdminLogin', 'token');
                })
            })
            .catch((getAdminError) => {
                if (getAdminError.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$router.push({
                        name: "AdminLogin",
                    });
                } else {
                    window.alert("Something went wrong...");
                }
            })
        }
    },
    methods:{
        checkDisabled() {
            return (this.isSaveClicked || (!this.newPackageName.trim() || this.newPackageName.trim() == '') || (!this.description.trim() || this.description.trim() == '') || (!this.descriptionMM.trim() || this.descriptionMM.trim() == '') || ( this.uploadedBanner == null && this.bannerName == null));
        },
        uuidv4() {
            return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        getPics() {
            this.attachmentFiles = null;
            if (this.uploadedBanner) {
                if (this.fileTypes.includes(this.uploadedBanner.type)) {
                    //giving the BLOB a structure
                    let reader = new FileReader();
                    reader.readAsDataURL(this.uploadedBanner);
                    reader.onload = function(e) {
                        //converting the structure to image
                        let img = new Image();
                        img.src = e.target.result;
                        img.onload = function (x) {
                            if (img.width==1029 && img.height==335) {
                                let fileInfo = {
                                    name: this.uploadedBanner.name,
                                    type: this.uploadedBanner.type,
                                    size: Math.round(this.uploadedBanner.size/1000)+' kB',
                                    base64: reader.result,      //converting img to Binary
                                    file: this.uploadedBanner   //BLOB
                                };
                                this.attachmentFiles = fileInfo;
                                this.imageURL = URL.createObjectURL(this.uploadedBanner);
                            } else {
                                window.alert("Please upload the image with 1029 x 335 dimension");
                                console.log("Provided dimension: ", this.width, " x ", this.height);
                                this.uploadedBanner = null;
                                this.attachmentFiles = null;
                            }
                        }.bind(this);
                    }.bind(this);
                } else {
                    this.uploadedBanner = null;
                    this.attachmentFiles = null;
                    window.alert("Not a valid file");
                }
            }
        },
        viewParticularAppointment(booking_id) {
          this.booking_id = booking_id;
          let routeData = this.$router.push({
            path: "/admin/viewParticularAppointment/" + booking_id,
          });
        },
        editMember()
        {
            this.isSaveClicked = true;
            var edit_body = {
                name: this.nameOfMember,
                staff: this.employeeIdOfMember,
                startDate: this.startDateForMember,
                endDate: this.endDateForMember,
                updatedBy: this.createdBy,
                corporateEmployeeId: this.employee_id,
                token : this.currentUser,
                typeOfUser : "ADMIN",

            }
            axios_auth_instance_admin.post("/editCorporateEmployee", edit_body)
            .then((editSuccess) =>{
                window.alert("Edited");
                this.$router.go();
            })
            .catch((editError) =>{
                this.isSaveClicked = false;
                handleError(editError, this.$router, 'editError', 'AdminLogin', 'token');
            });
        },
        editPackage()
        {
            this.$router.push({
                path:"/admin/EditBenefitsCorporatePackage/" +this.packageID
            });
        },
        editCorporatePackage(){
            this.isSaveClicked = true;

            var fileName;

            //For BANNER-PRESIGNED-URL-WRITE
            if(this.uploadedBanner == null && this.bannerName == null) {
                // console.log("Banner not uploaded!");
            } 
            if (this.uploadedBanner == null && this.bannerName != null) {
                // console.log("Using Existing Banner: ", this.bannerName);
                fileName = this.bannerName;
            } 
            else {
                if(this.bannerName != null) {
                    //deleting existing banner
                    var deleteBody = {
                        file_name: this.bannerName,
                        token: this.currentUser,
                        typeOfUser: "ADMIN",
                    }
                    
                    axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteBannerFromS3Bucket", deleteBody)
                    .then((deleteSuccess) => {
                        // console.log("deleteSuccess: ", deleteSuccess);
                    })
                    .catch((deleteError) => {
                        // console.log("deleteError: ", deleteError);
                    })
                }

                //making the banner ready for S3 uploading
                fileName = this.uuidv4() + this.uploadedBanner.name.split(" ").join("_").toLowerCase();
                this.bannerName = fileName;
                // console.log("this.bannerName: ", this.bannerName);
                this.sampleFileLists.push({
                    name: fileName,
                    uploadStatus: "Uploading",
                    size: this.uploadedBanner.size,
                    type: this.uploadedBanner.type
                })

                var active_file = this.uploadedBanner;
                var getPresignedURLBody = {
                    "file_name": fileName,
                    "file_type": active_file.type,
                    "token" : this.currentUser,
                    "typeOfUser" : "ADMIN"
                }

                //large file handling
                if (this.uploadedBanner.size >= 10485760) {
                    this.sampleFileLists.forEach(function(item){
                        if(item.name == fileName){
                        item.uploadStatus = "LargeFile"
                        }
                    });
                } else { 
                    axios.post(process.env.VUE_APP_BACKEND_URL + "/getPresignedURLWriteForBanner", getPresignedURLBody)
                    .then((getPresignedURLSuccess) => {
                        var fields = getPresignedURLSuccess.data.data.fields;
                        var url = getPresignedURLSuccess.data.data.url;

                        var post_body = {
                            bucket: fields.bucket,
                            ...fields,
                            'Content-Type': active_file.type,
                            file: active_file
                        };

                        const formData = new FormData();
                        for (const name in post_body) {
                            formData.append(name, post_body[name]);
                        }

                        axios.post(url, formData)
                        .then((imageUploadSuccess) => {
                            // console.log("Banner Uploaded successfully ", imageUploadSuccess);

                            var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                            this.sampleFileLists.forEach(function(item){
                                if(item.name == uploadedFile.name ){
                                    item.uploadStatus = "Completed"
                                    this.filesArray.push(item)
                                }
                            }.bind(this))
                        }).catch((imageUploadFail) => {
                            console.log("Banner upload Fail", imageUploadFail);

                            var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                            this.sampleFileLists.forEach(function(item){
                                if(item.name == uploadedFile.name && item.size > 10000000 ){
                                    item.uploadStatus = "LargeFile"
                                }
                                else if(item.name == uploadedFile.name) {
                                    item.uploadStatus = "Failed"
                                }
                            });
                        })
                    }).catch((getPresignedURLError) =>{
                        console.log("getPresignedURLError Error: ", getPresignedURLError);
                    });
                }
            }

            let editCorporatePackageRequest = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                corporatePackageId : this.packageID,
                packageName : this.newPackageName.replace(/\s+/g,' ').trim(),
                description : this.description ? this.description.replace(/\s+/g,' ').trim() : '',
                descriptionMM : this.descriptionMM ? this.descriptionMM.replace(/\s+/g,' ').trim() : '',
                updatedBy : this.createdBy,
                nameChange : ((this.newPackageName.replace(/\s+/g,' ').trim() == this.packageName.replace(/\s+/g,' ').trim()) ? false : true),
                fileName : fileName
            };

            axios.post(process.env.VUE_APP_BACKEND_URL + "/editCorporatePackage", editCorporatePackageRequest)
            .then((createCorporatePackageResponse) => {
                window.alert("Corporate Package edited successfully!");
                this.isSaveClicked = false;
                this.$router.go();
            })
            .catch((createCorporatePackageError) => {
                    this.newPackageName = "";
                    this.description = "";
                    this.descriptionMM = "";
                    this.isSaveClicked = false;
                    handleError(createCorporatePackageError, this.$router, 'createCorporatePackageError', 'AdminLogin', 'token');
            });
        },
        viewEmployeeDets(user)
        {
            this.current_display_user = user.name;
            this.isViewClicked = true;
            var claimed_benefits_body = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                employee_id: user._id,
            };
            axios.post(process.env.VUE_APP_BACKEND_URL + "/getClaimedBenefitsOfUser", claimed_benefits_body)
            .then((claim_success) => {
                this.claimed_appointments = claim_success.data.claimedAppointmentData;
                this.viewClaimedAppointmentModel = true;
                this.isViewClicked = false;
            })
            .catch((claim_error) =>{
                window.alert("Something went wrong while displaying claim data");
                console.log(claim_error);
                this.isViewClicked = false;
            })
        },
        editEmployee(user)
        {
            this.nameOfMember = user.name;
            this.employeeIdOfMember = user.employee_id;
            this.phone_number = user.phone_number;
            this.startDateForMember = user.start_date;
            this.endDateForMember = user.end_date;
            this.employee_id = user._id;
            this.editSingleMemberModal = true;
        },
        closeEditEmployee()
        {
            this.nameOfMember = "";
            this.employeeIdOfMember = "";
            this.phone_number = "";
            this.startDateForMember = "";
            this.endDateForMember = "";
            this.editSingleMemberModal = false;
        },
        openAddPopup()
        {
            this.addSingleMembersModal = true;
        },
        addSingleMemberModalClose(){
            this.addSingleMembersModal=false;
            this.isLoading = true;
            let request = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                corporatePackageId: this.packageID
            };

            axios.post(process.env.VUE_APP_BACKEND_URL + "/readCorporateEmployeesOfPackage", request)
            .then((readCorporateEmployeesSuccess) => {
                if(this.memberListOfPackage.length < readCorporateEmployeesSuccess.length){
                    this.memberListOfPackage = readCorporateEmployeesSuccess;
                    this.isLoading = false;
                }
                else{
                    // console.log("[INFO] No new employees added.");
                    this.isLoading = false;
                }
            })
            .catch((readCorporateEmployeesError) => {
                this.isLoading = false;
                handleError(readCorporateEmployeesError, this.$router, 'readCorporateEmployeesError', 'AdminLogin', 'token');
            });
        },
        openEditPackagePopup(){
            this.editCorporatePackageModel = true;
            this.newPackageName = this.packageName;
            this.description = this.packageDescription;
            this.descriptionMM = this.packageDescriptionMM;

            //For BANNER-PRESIGNED-URL-READ
            if(this.bannerName == null) {
                // console.log("No banner exists");
            } else {
                // console.log("Banner exists: ", this.bannerName);

                var readBody = {
                    file_name: this.bannerName,
                    token: this.currentUser,
                    typeOfUser: "ADMIN",
                }
                
                axios.post(process.env.VUE_APP_BACKEND_URL + "/getPresignedURLReadForBanner", readBody)
                .then((readSuccess) => {
                    // console.log("readSuccess: ", readSuccess);
                    this.imageURL = readSuccess.data.data;
                    // console.log("URL = ", this.imageURL);
                })
                .catch((readError) => {
                    console.log("readError: ", readError);
                })
            }



        },
        createMember(){
            this.isSaveClicked = true;
            // this.employeesList.push({employee_id:this.employeeIdOfMember, employee_name:this.nameOfMember, phone_number:this.phone_number, date_of_birth:this.dobOfMember, start_date:this.startDateForMember, end_date: this.endDateForMember})
            this.employeesList.push({employee_id:this.employeeIdOfMember, employee_name:this.nameOfMember, phone_number:this.phone_number, start_date:this.startDateForMember, end_date: this.endDateForMember})
            var createMemberBody ={
              corporatePackageId:this.packageID,
              employeesList:this.employeesList,
              createdBy:this.createdBy,
              token : this.currentUser,
              typeOfUser : "ADMIN",
            }
            
            axios_auth_instance_admin.post("/createListOfCorporateEmployees",createMemberBody)
            .then((createListOfCorporateEmployeesResponse)=>{
                window.alert("Added Employee");
                this.$router.go();
            })
            .catch((createListOfCorporateEmployeesError)=>{
                this.isSaveClicked = false;
                console.log("createListOfCorporateEmployeesError", createListOfCorporateEmployeesError)
                window.alert("Something went wrong while creating member");
                handleError(createListOfCorporateEmployeesError, this.$router, 'createListOfCorporateEmployeesError', 'AdminLogin', 'token');
            })

        }
    },
}
</script>
<style scoped>
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 700;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
}
table > tr > td:last-child {
  white-space: nowrap;
}
.addSingleMemberMainClass{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.titleAndTextFiledClass{
    padding-right: 70px;
    display: flex;
    flex-direction: column;
}
.corporateNameCard{
    margin-top:24px;
    padding: 10px;
}
.viewCorporatePackageTitle{
    color: #828282;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.viewCorporatePackageValue{
    color: #333333;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.viewCorporateBenefitsCard{
    margin-top:24px;
    padding: 10px;
}
.viewBenefitsCardTitle{
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}
.wordBreakClass{
      width: 100%;
      word-break: break-word;
      /* margin-top: -25px; */
}
.viewCorporateBenefitsDetails{
    display: flex;
    flex-direction: row;
    margin-top:24px;
    padding: 10px;
    justify-content: space-between;
}
.showMoreBtn{
    background: #E4F5FF;
    color: #48ACEF;
    position: absolute;
    bottom: 0;
}
.pageHeaderClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.memberlistPageAction{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right:20px;
    margin-top:24px;
}
.createCorporateDilogTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.programBenefitsClass{
    display: flex;
    justify-content: space-between;
    padding: 4px;
}
.textAndButtonClass{
    display: flex;
    justify-content: space-between;
    padding: 4px;
}
.corporateContentClass{
    display: flex;
    flex-direction: row;
}
.descriptionRow{
    display: flex;
    flex-direction: row;
    padding : 4px;
}
</style>
<style>
.corporatePackageTable .v-data-table-header th{
    background: #1467bf !important;
    color: white !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
}
.corporatePackageTable tbody td{
    font-size: 8pt !important;
    font-weight: 700 !important;
}
.scrollFix {
    overflow-y: scroll;
    max-height: 94.5vh;
}
</style>